<template>
  <section class="Content-Page">
    <Loader :loading="showLoader" />
    <div class="page-header">
      <h3 class="page-title">Offer Packages</h3>
      <nav aria-label="breadcrumb">
        <div class="float-right">
          <router-link :to="`/offer-packages/add-offer`" class="btn btn btn-add btn-primary">Add New
          </router-link>
        </div>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="table-responsive mb-0">
            <table class="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Title</th>
                  <th>Code</th>
                  <!-- <th>Decsription</th> -->
                  <th>Price</th>

                  <th>Total OFF</th>
                  <th>Percentage OFF</th>
                  <th>Qty</th>
                  <th>Expiration date</th>
                  <th>Active Flag</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody v-if="info">
                <tr v-for="(item, index) in info" :key="item.id">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.title }}</td>
                  <td>{{ item.code }}</td>
                  <!-- <td>{{ item.description }}</td> -->
                  <td>{{ item.price }}</td>

                  <td>{{ item.total_off }}</td>
                  <td>{{ item.percentage_off }}</td>
                  <td>{{ item.qty }}</td>
                  <td> {{ moment(item.expiration_data).format('MMMM Do YYYY') }}</td>

                  <td>
                    <label class="switch">
                      <input type="checkbox" @change="onToggleChange(item.id, item.active)" v-model="item.active" />
                      <span class="slider round"></span> </label><br /><br />
                  </td>
                  <td>
                    <router-link class="mr-2 table-icon" v-b-tooltip.hover title="Edit"
                      :to="`/offer-packages/edit-offer/${item.id}`">
                      <i class="mdi mdi-pencil "></i>
                    </router-link>
                    <!-- <a v-on:click="deleteFile(item.id)"  class="mr-2 table-icon" v-b-tooltip.hover title="Delete">
                      <i class="mdi mdi-delete"></i>
                    </a> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12 p-3 text-center" v-if="info == ''">
            No data found!
          </div>
          <div style="border-top: 1px solid #e9ecef" v-if="info">
            <paginate  :page-count="totalPage" :page-range="3" :margin-pages="2"
              :click-handler="clickCallback" :prev-text="'Prev'" :next-text="'Next'" :container-class="'pagination'"
              :page-class="'page-item'">
            </paginate>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment'
import Paginate from 'vuejs-paginate'
import { offerApi } from "../../api";
import Loader from "../../components/Loader";
export default {
  name: "offer-list",
  components: {
    Loader, Paginate
  },
  data() {
    return {
      info: null,
      showLoader: false,
      totalPage: this.totalPage,
      moment
    };
  },
  mounted() {
    let page = 1;
    this.list(page);
  },
  methods: {
    async list(page) {
      this.showLoader = true;
      const { data } = await offerApi.list(page);
      this.info = data.data.data.data;
      this.totalPage = data.data.data.last_page;
      this.current_page = data.data.data.current_page;
      this.showLoader = false;

    },
    clickCallback: function (page) {
      this.list(page)
    },
    deleteFile(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.showLoader = true;
          const response = offerApi.delete(id).then((response) => {
            this.showLoader = false;
            if (response.data.code == 200) {
              this.list(page)
              if (result.value) {
                this.$swal(
                  "Deleted!",
                  "Your file has been deleted.",
                  "success"
                );
              }
            }
          });
        }
      });
    },
    async onToggleChange(id, event) {
      if (event == true) {
        event = 1;
        console.log(event);
      } else {
        event = 0;
      }
      this.body = { active: event };
      this.showLoader = true;
      const data = await offerApi.editOffer(id, {
        ...this.body,
      });
      this.showLoader = false;
      this.list(1);
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.messages,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.messages,
        });
      }
    },
  },
};
</script>

<style scoped>

</style>
